import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { Asset, AssetDataResp, AssetFieldsResp, AssetFiles, AssetFilesResp, AssetRequest, AssetResp, AssetTypesResp } from '../models/asset';
import { MappingModel } from '../models/users';
import { CommonService } from './common.service';
import { FileModel } from '../models/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  private assetImageCache = new Map<string, Observable<any>>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
  ) { }

  listAssetTypes$(tenantId: string, params?: any): Observable<AssetTypesResp> {
    return this._http.get<AssetTypesResp>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-types`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getAssetFields$(tenantId: string, params?: any): Observable<AssetFieldsResp> {
    return this._http.get<AssetFieldsResp>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-fields`, { params: this._commonService.buildParams(params) });
  }

  createAsset$(tenantId: string, assetRequest: AssetRequest): Observable<Asset> {
    return this._http.post<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets`, assetRequest);
  }

  listAssets$(tenantId: string, params?: any): Observable<AssetResp> {
    return this._http.get<AssetResp>(`${VECTORE_API_BASE_URL}/${tenantId}/assets`, { params: this._commonService.buildParams(params) });
  }

  getAssetById$(tenantId: string, assetId: string, params?: any): Observable<Asset> {
    return this._http.get<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, { params: this._commonService.buildParams(params) });
  }

  updateAsset$(tenantId: string, assetId: string, asset: Asset): Observable<Asset> {
    return this._http.patch<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, asset);
  }

  deleteAsset$(tenantId: string, assetId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getAssetImage$(tenantId: string, assetId: string, width?: number, height?: number): Observable<SafeUrl> {
    const cacheKey = `${tenantId}-${assetId}-${width}-${height}`;
    if (!this.assetImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      })
        .pipe(
          shareReplay(1),
          tap({
            error: error => this._commonService.hanleErrorImage(error)
          })
        );
      this.assetImageCache.set(cacheKey, requestObservable);
    }
    return this.assetImageCache.get(cacheKey)
      .pipe(
        map(response => {
          if (response) {
            let urlCreator = window.URL || window.webkitURL;
            return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
          }
          return null;
        })
      );
  }

  exportAssets$(tenantId: string, exportData: MappingModel, params?: any): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/assets`, exportData, {
      observe: 'response',
      responseType: 'blob',
      params: this._commonService.buildParams(params)
    })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  linkAssetFile$(tenantId: string, assetId: string, fileModel: FileModel): Observable<HttpResponse<AssetFiles>> {
    return this._http.post<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files`, fileModel, { observe: 'response' });
  }

  listAssetFiles$(tenantId: string, assetId: string, params?: any): Observable<AssetFilesResp> {
    return this._http.get<AssetFilesResp>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files`, { params: this._commonService.buildParams(params) });
  }

  getAssetFileById$(tenantId: string, assetId: string, fileId: string, params?: any): Observable<AssetFiles> {
    return this._http.get<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`, { params: this._commonService.buildParams(params) });
  }

  updateAssetFile$(tenantId: string, assetId: string, fileId: string, fileModel: FileModel): Observable<AssetFiles> {
    return this._http.patch<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`, fileModel);
  }

  deleteAssetFile$(tenantId: string, assetId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`, { observe: 'response' });
  }

  downloadAssetFile$(tenantId: string, assetId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}/content`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  getAssetData$(tenantId: string, params?: any): Observable<AssetDataResp> {
    return this._http.get<AssetDataResp>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-data`, { params: this._commonService.buildParams(params) });
  }
}



