import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';
import { Notification } from 'src/app/shared/models/me/me';
import { MeService } from 'src/app/shared/api-services/new-me.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DatePipe } from '@angular/common';
import { Booking, BookingClient } from 'src/app/shared/models/booking';
import { BookingUtilsService } from 'src/app/shared/utilities/booking-utils.service';
import { NotificationRequest } from 'src/app/shared/models/me/me-request';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  private tenantId: string = this._tenantService.getTenantId();

  notificationsViewModel: NotificationViewModel[];

  @Output() haveNotificationsUnReadEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean = false;

  limit: number = 20;

  private subscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _newMeService: MeService,
    private _datePipe: DatePipe,
    private _bookingUtilsService: BookingUtilsService,
  ) { }

  ngOnInit(): void {

    this.subscription = this._newMeService.me$.pipe(
      takeUntil(this.destroy$),
      tap((response) => {
        if (response) {
          this.getNotifications()
        }
      }),
    ).subscribe({
      complete: () => this.subscription.unsubscribe()
    });
  }

  private getNotifications() {
    this.isLoading = true;
    let params: NotificationRequest = {
      limit: this.limit,
      isRead: false,
      orderBy: '-Id',
    };
    this._newMeService.listNotifications$(this.tenantId, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: Notification[]) => {
          this.notificationsViewModel = response.map((n: Notification) => {
            if (n.notificationType.split('_')[0] === 'BOOKING') {
              return this.setBookingNotificaiton(n);
            }
            if (n.notificationType.split('_')[0] === 'TASK') {
              return this.setTaskNotification(n);
            }
            if (n.notificationType.split('_')[0] === 'USER') {
              return this.setUserNotification(n);
            }
            return null;
          });
          this.checkIfHasNotifications();
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  private setTaskNotification(notification: Notification): NotificationViewModel | null {

    if (notification.notificationType === 'TASK_ASSIGNED') {
      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'icon-task-vectore',
        title: notification.notificationType,
        description: notification.data['TaskTitle'],
        time: new Date(notification.sendDate),
        link: `/${RoutesUrl.TASKS}`,
        queryParams: {
          modalEditTask: notification.referenceId
        },
      };
    }

    if (notification.notificationType === 'TASK_UPDATED') {
      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'icon-task-vectore',
        title: notification.notificationType,
        description: notification.data['TaskTitle'],
        time: new Date(notification.sendDate),
        link: `/${RoutesUrl.TASKS}`,
        queryParams: {
          modalEditTask: notification.referenceId
        },
      };
    }

    if (notification.notificationType === 'TASK_EXPIRED') {
      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'icon-task-vectore',
        title: notification.notificationType,
        description: notification.data['TaskTitle'],
        time: new Date(notification.sendDate),
        link: `/${RoutesUrl.TASKS}`,
        queryParams: {
          modalEditTask: notification.referenceId
        },
      };
    }

    return null;
  }

  private setBookingNotificaiton(notification: Notification): NotificationViewModel | null {
    if (notification.notificationType === 'BOOKING_UPDATED') {
      let startDate = new Date(notification.data['BookingStartDate']);

      let booking: BookingClient = {
        status: notification.data['BookingStatus'],
      }
      booking = this._bookingUtilsService.setStatusBadgeBooking(booking);

      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'bi-bookmarks',
        title: notification.notificationType,
        badge: booking.statusClient.text,
        badgeClass: booking.statusClient.cssClass,
        description: notification.data['BookingTitle'],
        footerDescription: `${notification.data['VehicleLicensePlate']} · ${this._datePipe.transform(startDate, 'HH:mm dd/MM')}`,
        time: new Date(notification.sendDate),
        link: `/${RoutesUrl.MYBOOKINGS}/${RoutesUrl.BOOKING_DETAIL}/${notification.referenceId}`,
      };
    }

    return null;
  }

  private setUserNotification(notification: Notification): NotificationViewModel | null {
    if (notification.notificationType === 'USER_ENABLED') {
      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'bi-person',
        title: notification.notificationType,
        description: 'USER_ENABLED_DESCRIPTION',
        time: new Date(notification.sendDate),
        queryParams: {
          modalEditUser: notification.referenceId
        }
      }
    }

    if (notification.notificationType === 'USER_DISABLED_MANAGER') {
      return <NotificationViewModel>{
        id: notification.id,
        avatar: 'bi-person-plus',
        title: notification.notificationType,
        description: notification.data['UserUserName'],
        time: new Date(notification.sendDate),
        link: `/${RoutesUrl.PERSONS}/${RoutesUrl.PERSONS_DETAIL}/${notification.referenceId}`,
        queryParams: {
          modalEditUser: notification.referenceId
        }
      }
    }

    return null;
  }

  checkIfHasNotifications(): void {
    let haveNotificationsUnRead = this.notificationsViewModel?.length > 0;
    this.haveNotificationsUnReadEmit.emit(haveNotificationsUnRead ?? false);
  }

  markNotificationAsRead(notificationId: string): void {
    let notificationRequest: NotificationRequest = {
      isRead: true,
    }
    this.isLoading = true;
    this._newMeService.updateNotification$(this.tenantId, notificationId, notificationRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          this.notificationsViewModel = [];
          this.getNotifications();
        }
      });
  }

  markAllNotificationsAsRead(): void {
    this._newMeService.markAsReadNotifications$(this.tenantId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        complete: () => {
          this.notificationsViewModel = [];
          this.getNotifications();
        }
      });
  }

  onScrollDown() {
    console.log('scroll down');
    this.limit += 10;
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

export interface NotificationViewModel {
  id?: string;
  avatar?: string;
  title?: string;
  badge?: string;
  badgeClass?: string;
  description?: string;
  footerDescription?: string;
  time?: Date;
  isCancelled?: boolean;
  link?: string;
  queryParams?: Params;
}