import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { VECTORE_API_BASE_URL } from '../constant/app-config';

import { CommonService } from './common.service';
import { Import, ImportField, ImportRequest, ImportResponse, ImportRow, ImportRowResponse, Importer, ImporterResponse, MappingExport } from '../models/import';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
  ) { }

  listImporters$(tenantId: string, params?: any): Observable<ImporterResponse> {
    return this._http.get<ImporterResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/importers`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImporterByName$(tenantId: string, importerName: string, params?: any): Observable<Importer> {
    return this._http.get<Importer>(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImporterFields$(tenantId: string, importerName: string, params?: any): Observable<ImportField[]> {
    return this._http.get<ImportField[]>(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}/fields`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createTemplate$(tenantId: string, importerName: string, mapping: MappingExport): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}/template`, mapping,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap({
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  createImport$(tenantId: string, importRequest: ImportRequest): Observable<Import> {
    return this._http.post<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports`, importRequest)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImports$(tenantId: string, params?: any): Observable<ImportResponse> {
    return this._http.get<ImportResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/imports`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImportById$(tenantId: string, importId: string, params?: any): Observable<Import> {
    return this._http.get<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  patchImport$(tenantId: string, importId: string, patchImportRequest: ImportRequest): Observable<Import> {
    return this._http.patch<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`, patchImportRequest);
  }

  deleteImport$(tenantId: string, importId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImportRowById$(tenantId: string, importId: string, importRowId: string): Observable<ImportRow> {
    return this._http.get<ImportRow>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows/${importRowId}`);
  }

  patchImportRow$(tenantId: string, importId: string, importRowId: string, patchImportRowRequest: ImportRow): Observable<ImportRow> {
    return this._http.patch<ImportRow>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows/${importRowId}`, patchImportRowRequest);
  }

  listImportRows$(tenantId: string, importId: string, params?: any): Observable<ImportRowResponse> {
    return this._http.get<ImportRowResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }
}
