import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TaskClient } from 'src/app/shared/models/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ModalExtraInformation, ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { Booking } from 'src/app/shared/models/booking';
import { TaskFilter } from 'src/app/shared/models/tasks/task-filter';
import { Subject, takeUntil, tap } from 'rxjs';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';

@Component({
  selector: 'card-tasks',
  templateUrl: './card-tasks.component.html',
  styleUrls: ['./card-tasks.component.scss']
})
export class CardTasksComponent implements OnInit, OnDestroy {

  tenantId: string;
  @Input() booking: Booking;
  listTaskClient: TaskClient[];

  private _destroy: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _taskUtilsService: TaskUtilsService,
    private _modalUtilsService: ModalUtilsService,
    private _communicationService: CommunicationService,
  ) {
    this.tenantId = this._tenantService.getTenantId();
  }

  ngOnInit(): void {
    this.getTasks();
    this._communicationService.getEvent()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.getTasks());
  }

  private getTasks() {
    if (this.booking?.id) {
      let params: TaskFilter = {
        bookingId: this.booking.id,
        includeAssignedUser: true,
        limit: 3,
        start: 0,
        orderBy: '-Id'
      };
      this._taskService.listTasks$(this.tenantId, params)
        .pipe(
          takeUntil(this._destroy),
        )
        .subscribe({
          next: (taskResp) => {
            this.listTaskClient = taskResp.items;
            this.listTaskClient.forEach((task: TaskClient) => {
              this._taskUtilsService.setStatusBadgeTask(task, task.taskType);
            });
          }
        });
    }
  }

  openCreateTaskModal() {
    let extraInfo: ModalExtraInformation = {
      booking: this.booking,
      vehicle: this.booking?.vehicle,
      user: this.booking?.user,
    };
    this._modalUtilsService.openCreateModalTask(false, extraInfo).pipe(
      takeUntil(this._destroy),
      tap((modalCreateTask) => {
        modalCreateTask.onHidden.subscribe(() => {
          this.getTasks();
        });
        return modalCreateTask;
      })
    ).subscribe();
  }

  openEditTaskModal(task: TaskClient) {
    this._modalUtilsService.openEditTaskModal(task.id).pipe(
      takeUntil(this._destroy),
      tap((modalEditTask) => {
        modalEditTask.onHidden.subscribe(() => {
          this.getTasks();
        });
        return modalEditTask;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}