import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import * as Permissions from 'src/app/shared/constant/permissions';
import { SidebarItem } from 'src/app/core/components/sidebar/sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class AccessUtilsService {

  constructor(
    private _translateService: TranslateService
  ) { }

  getAllNavs(permissions: string[] = [], isMobile: boolean = false): SidebarItem[] {
    let listNavs: SidebarItem[] = this.setSections(isMobile);

    if (permissions.length === 0 || permissions === null) {
      return [
        { name: 'homepage', label: this._translateService.instant('HOMEPAGE.NAME'), color: 'color-danger', icon: 'bi bi-ban', routerLink: RoutesUrl.HOME },
      ];
    }

    //check permissions
    const filteredNavs = this.filterNavbar(listNavs, permissions);

    return filteredNavs;
  }

  private filterNavbar(listNavs: SidebarItem[], permissions: string[]) {
    return listNavs.filter(nav => {
      // Se l'elemento non ha permessi e non ha figli, mostra sempre
      if (!nav.permissions && !nav.children) {
        return true;
      }

      // Controlla se l'utente ha almeno uno dei permessi richiesti per la pagina
      const hasPermission = permissions.some(permission => nav.permissions?.includes(permission));

      // Se il menu ha figli, applica la stessa logica ai figli
      if (nav.children) {
        nav.children = nav.children.filter(child => {
          if (!child.permissions) {
            return true; // Mostra i figli senza permessi
          }
          const hasPermission = permissions.some(permission => child.permissions?.includes(permission));
          return hasPermission;
        });

        // Mostra l'elemento genitore se ha almeno un figlio visibile
        return nav.children.length > 0;
      }

      // Mostra l'elemento se ha permessi corrispondenti
      return hasPermission;
    });
  }

  private setSections(isMobile: boolean) {
    let listNavs: SidebarItem[] = [
      {
        name: 'dashboard',
        label: this._translateService.instant('DASHBOARD.NAME'),
        color: 'color-primary',
        icon: 'bi bi-grid',
        routerLink: RoutesUrl.DASHBOARD,
      },
      {
        name: 'vehicles',
        label: this._translateService.instant('VEHICLESS'),
        icon: 'bi bi-truck',
        canExpand: true,
        isExpanded: false,
        children: [
          {
            name: 'vehicles',
            label: this._translateService.instant('VEHICLESS'),
            icon: 'bi bi-truck',
            routerLink: RoutesUrl.VEHICLES,
            permissions: [Permissions.CAN_READ_VEHICLES, Permissions.CAN_MANAGE_VEHICLES]
          },
          {
            name: 'vehicle-usages',
            label: this._translateService.instant('VEHICLE_USAGES.NAME'),
            icon: 'bi bi-truck',
            routerLink: RoutesUrl.VEHICLE_USAGES,
            permissions: [Permissions.CAN_READ_VEHICLE_USAGES, Permissions.CAN_MANAGE_VEHICLE_USAGES]
          },
        ]
      },
      {
        name: 'page-assets',
        label: this._translateService.instant('ASSET'),
        icon: 'bi bi-layers',
        routerLink: RoutesUrl.ASSETS,
        permissions: [Permissions.CAN_READ_ASSETS, Permissions.CAN_MANAGE_ASSETS]
      },
      {
        name: 'persons',
        label: this._translateService.instant('PERSONS.NAME'),
        icon: 'bi bi-people',
        routerLink: RoutesUrl.PERSONS,
        permissions: [Permissions.CAN_READ_USERS, Permissions.CAN_MANAGE_USERS]
      },
      {
        name: 'activity',
        label: this._translateService.instant('ACTIVITY'),
        icon: 'icon-task-vectore',
        canExpand: true,
        isExpanded: false,
        children: [
          {
            name: 'tasks',
            label: this._translateService.instant('TASK'),
            icon: 'icon-task-vectore',
            routerLink: RoutesUrl.TASKS,
            permissions: [Permissions.CAN_READ_TASKS, Permissions.CAN_MANAGE_TASKS]
          },
          {
            name: 'costs',
            label: this._translateService.instant('COSTS.NAME'),
            icon: 'bi bi-wallet2',
            routerLink: RoutesUrl.COSTS,
            permissions: [Permissions.CAN_READ_COSTS, Permissions.CAN_MANAGE_COSTS]
          },
          {
            name: 'invoices',
            label: this._translateService.instant('INVOICES.NAME'),
            icon: 'bi bi-receipt-cutoff',
            routerLink: RoutesUrl.INVOICES,
            permissions: [Permissions.CAN_READ_INVOICES, Permissions.CAN_MANAGE_INVOICES]
          },
          {
            name: 'suppliers',
            label: this._translateService.instant('SUPPLIERS.NAME'),
            icon: 'bi bi-shop-window',
            routerLink: RoutesUrl.SUPPLIERS,
            permissions: [Permissions.CAN_READ_SUPPLIERS, Permissions.CAN_MANAGE_SUPPLIERS]
          },
        ]
      },
      {
        name: 'bookings',
        label: this._translateService.instant('BOOKINGS.NAME'),
        icon: 'bi bi-bookmarks',
        canExpand: true,
        isExpanded: false,
        children: [
          {
            name: 'fleetmanager-booking',
            label: this._translateService.instant('BOOKINGS.NAME'),
            routerLink: RoutesUrl.FLEETMANAGER_BOOKING,
            permissions: [Permissions.CAN_ADMIN_BOOKINGS, Permissions.CAN_READ_BOOKINGS]
          },
          {
            name: 'my-bookings',
            label: this._translateService.instant('YOUR_BOOKINGS'),
            routerLink: RoutesUrl.MYBOOKINGS,
            permissions: [Permissions.CAN_MANAGE_BOOKINGS]
          },
        ]
      },
      {
        name: 'reports',
        label: this._translateService.instant('REPORTS.NAME'),
        icon: 'bi bi-bar-chart',
        routerLink: RoutesUrl.REPORTS,
        permissions: [Permissions.CAN_READ_REPORTS]
      },
      { name: 'fleet-location', label: this._translateService.instant('FLEET_LOCATION'), icon: 'bi bi-geo-alt', routerLink: RoutesUrl.FLEETLOCATION, permissions: [Permissions.CAN_MANAGE_VEHICLE_USAGES] },
      {
        name: 'settings',
        label: this._translateService.instant('SETTINGS.NAME'),
        icon: 'bi bi-gear',
        routerLink: RoutesUrl.SETTINGS,
        permissions: [Permissions.CAN_MANAGE_TAGS, Permissions.CAN_READ_TAGS, Permissions.CAN_MANAGE_HEADQUARTERS, Permissions.CAN_READ_HEADQUARTERS, Permissions.CAN_MANAGE_LOCATIONS, Permissions.CAN_READ_LOCATIONS, Permissions.CAN_MANAGE_VERSIONS, Permissions.CAN_READ_VERSIONS]
      },
      { name: 'assistance', label: this._translateService.instant('ASSISTANCE'), icon: 'bi bi-headset', color: 'assistance', href: 'https://www.vectore.it/contatti/' },
    ];

    if (isMobile) {
      listNavs.unshift({ name: 'homepage', label: this._translateService.instant('HOMEPAGE.NAME'), color: 'color-danger', icon: 'bi bi-house', routerLink: RoutesUrl.HOME });
    }
    return listNavs;
  }

}