import { Component, Input } from '@angular/core';
import { Observable, Subject, forkJoin,  of, takeUntil, tap } from 'rxjs';
import { Task, TaskRequest } from 'src/app/shared/models/task';
import { TaskFilter } from 'src/app/shared/models/tasks/task-filter';
import { MeServiceDeprecated } from 'src/app/shared/api-services/me.service';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { Me } from 'src/app/shared/models/me/me';

@Component({
  selector: 'button-love',
  template: `
    <button type="button" class="btn-follow btn-lightgray rounded-1" (click)="onClickButton()">    
      <i *ngIf="isFollowed; else unfollowed" class="bi bi-heart-fill"></i>
      <ng-template #unfollowed>
          <i class="bi bi-heart"></i>
      </ng-template>

      <span class="btn-text text-nowrap" *ngIf="isFollowed">{{'TASKS.BUTTONS.FOLLOW' | translate}}</span>
      <span class="btn-text text-nowrap" *ngIf="!isFollowed">{{'TASKS.BUTTONS.UNFOLLOW' | translate}}</span>
    </button>
  `,
  styleUrls: ['./button-love.component.scss']
})
export class ButtonLoveComponent {
  tenantId: string = this._tenantService.getTenantId();

  @Input() taskId: string;
  task: Task;
  taskRequest: TaskRequest;
  me : Me;

  isFollowed = false;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _meService: MeServiceDeprecated,
    private _taskService: TasksService,
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit() {
    this.checkFollowed();

    this._communicationService.getSpecificEvent(this.taskId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.checkFollowed());
  }

  private checkFollowed() {
    forkJoin({
      task: this.getTask(),
      me: this.getMe()
    })
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: ({task, me}) => {
        this.isFollowed = task?.taskWatcherIds?.includes(me.id);
      }
    });
  }

  private getTask() : Observable<Task> {
    let params : TaskFilter = {
      includeTaskWatchers: true
    }

    return this._taskService.getTaskId$(this.tenantId, this.taskId, params).pipe(
      tap(response => {
        this.taskRequest = this._taskService.mapTaskToTaskRequest(response);
        this.task = response;
      })
    );
  }

  private getMe() : Observable<Me> {
    if (this.me) {
      return of(this.me);
    }

    return this._meService.getMe$(this.tenantId).pipe(
      tap(response => {
        this.me = response;
      })
    );
  }

  onClickButton() {
    if (this.isFollowed) {
      this.unfollowTask();
    } else {
      this.followTask();
    }
  }

  followTask() {
    if (!this.task.taskWatcherIds.includes(this.me.id)) {
      this.taskRequest.taskWatcherIds.push(this.me.id);
      this.updateTaskWatcherIds();
    }
  }

  unfollowTask() {
    if (this.task.taskWatcherIds.includes(this.me.id)) {
      this.taskRequest.taskWatcherIds = this.taskRequest.taskWatcherIds.filter(id => id !== this.me.id);
      this.updateTaskWatcherIds();
    }
  }

  private updateTaskWatcherIds() {
    this._taskService.patchTasks$(this.tenantId, this.task.id, this.taskRequest).subscribe(
      response => {
        this.task = response;
        this.isFollowed = this.task.taskWatcherIds.includes(this.me.id);
        this._communicationService.sendSpecificEvent(this.task.id);
        this._communicationService.sendSpecificEventWithData(this.task.id, this.task);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}