import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, shareReplay, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import {
  UserFieldModelPagedListModel,
  RequestUserModel,
  UserModel,
  UserModelResponse,
  MappingModel,
  CreateUserFileModel,
  UserFileModel,
  ItemUserFileModelPagedListModel,
  PatchUserFileModel,
  ItemUserDataModelPagedListModel,
  UserDataByTagResp,
  UserGroup
} from '../models/users';
import { CommonService } from './common.service';
import userFieldsConfigData from 'src/assets/configurations/mapping-extrafields/user-fields-configuration.json'
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private baseUrl = VECTORE_API_BASE_URL;
  private userImageCache = new Map<string, Observable<any>>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _domSanitizer: DomSanitizer,
  ) { }

  // List user groups
  listUserGroups$(tenantId: string, params: any): Observable<UserGroup[]> {
    return this._http.get<UserGroup[]>(`${this.baseUrl}/${tenantId}/user-groups`,
      { params: this._commonService.buildParams(params) });
  }

  // Get a user group
  getUserGroup$(tenantId: string, userGroupId: string): Observable<UserModel> {
    return this._http.get<UserModel>(`${this.baseUrl}/${tenantId}/user-groups/${userGroupId}`);
  }

  // List user fields
  getUserFields$(tenantId: string, params?: any): Observable<UserFieldModelPagedListModel> {
    return this._http.get<UserFieldModelPagedListModel>(`${this.baseUrl}/${tenantId}/user-fields`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  // Create new user
  createUser$(tenantId: string, userData: RequestUserModel): Observable<UserModel> {
    return this._http.post<UserModel>(`${this.baseUrl}/${tenantId}/users`, userData);
  }

  // List users
  listUsers$(tenantId: string, params: any): Observable<UserModelResponse> {
    return this._http.get<UserModelResponse>(`${this.baseUrl}/${tenantId}/users`, { params: this._commonService.buildParams(params) });
  }

  // Get user info
  getUserById$(tenantId: string, userId: string, params?: any): Observable<UserModel> {
    return this._http.get<UserModel>(`${this.baseUrl}/${tenantId}/users/${userId}`, { params: this._commonService.buildParams(params) });
  }

  // Update user info
  updateUserInfo$(tenantId: string, userId: string, userData?: RequestUserModel): Observable<UserModel> {
    return this._http.patch<UserModel>(`${this.baseUrl}/${tenantId}/users/${userId}`, userData)
      .pipe(
        shareReplay(1),
        tap(response => {
          if (response.id) {
            this._toastrService.success(this._translateService.instant('PERSONS.MESSAGES.SUCCESS_UPDATED'));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      )
  }

  // Delete user
  deleteUser$(tenantId: string, userId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this.baseUrl}/${tenantId}/users/${userId}`, { observe: 'response' });
  }

  // Export users
  exportUsers$(tenantId: string, exportData: MappingModel, params?: any): Observable<HttpResponse<Blob>> {
    return this._http.post(`${this.baseUrl}/${tenantId}/export/users`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  // Add a file to a user
  addUserFile$(tenantId: string, userId: string, fileData: CreateUserFileModel): Observable<HttpResponse<UserFileModel>> {
    return this._http.post<UserFileModel>(`${this.baseUrl}/${tenantId}/users/${userId}/files`, fileData, { observe: 'response' });
  }

  // List all user's files
  listUserFiles$(tenantId: string, userId: string, params?: any): Observable<ItemUserFileModelPagedListModel> {
    return this._http.get<ItemUserFileModelPagedListModel>(`${this.baseUrl}/${tenantId}/users/${userId}/files`, { params: this._commonService.buildParams(params) });
  }

  // Get user file info
  getUserFileInfo$(tenantId: string, userId: string, userFileId: string): Observable<UserFileModel> {
    return this._http.get<UserFileModel>(`${this.baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`);
  }

  // Update user file info
  updateUserFileInfo$(tenantId: string, userId: string, userFileId: string, fileData: PatchUserFileModel): Observable<UserFileModel> {
    return this._http.patch<UserFileModel>(`${this.baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`, fileData);
  }

  // Delete user file
  deleteUserFile$(tenantId: string, userId: string, userFileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this.baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`, { observe: 'response' });
  }

  // Download user file content
  downloadUserFileContent$(tenantId: string, userId: string, userFileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${this.baseUrl}/${tenantId}/users/${userId}/files/${userFileId}/content`,
      { observe: 'response', responseType: 'blob' });
  }

  // List user data
  listUserData$(tenantId: string, params?: any): Observable<ItemUserDataModelPagedListModel> {
    return this._http.get<ItemUserDataModelPagedListModel>(`${this.baseUrl}/${tenantId}/user-data`, { params: this._commonService.buildParams(params) });
  }

  listUserDataByTag$(tenantId: string, params?: any): Observable<UserDataByTagResp> {
    return this._http.get<UserDataByTagResp>(`${this.baseUrl}/${tenantId}/user-data-by-tag`, { params: this._commonService.buildParams(params) });
  }

  getUserImage$(tenantId: string, userId: string, width?: number, height?: number, safe: boolean = true): Observable<SafeUrl | string | null> {
    const cacheKey = `${tenantId}-${userId}-${width}-${height}`;

    if (!this.userImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get<any>(`${this.baseUrl}/${tenantId}/users/${userId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      }).pipe(
        shareReplay(1),
        catchError(error => this._commonService.hanleErrorImage(error))
      );

      this.userImageCache.set(cacheKey, requestObservable);
    }
    return this.userImageCache.get(cacheKey).pipe(
      map(response => {
        if (response) {
          let urlCreator = window.URL || window.webkitURL;
          if (!safe) {
            return urlCreator.createObjectURL(response);
          }
          return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
        }
        return null;
      })
    );
  }

  mergeWithConfig(tenantId: string, fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    let configFilterByTenant = tenantId === null ? userFieldsConfigData : userFieldsConfigData.filter(x => x.tenant === tenantId || x.tenant === null);

    // Crea un array temporaneo che include l'ordine
    let tempConfigs: { config: FormlyFieldConfig; order: number }[] = [];

    fields.forEach(field => {
      let configData = configFilterByTenant.find(x => x.name === field.name);

      if (configData) {
        let config: FormlyFieldConfig = {
          key: field.key,
          type: configData?.type ?? 'input',
          templateOptions: {
            label: this._translateService.instant(field.name),
            type: (configData.templateOptions as any)?.type ?? field.type,
            required: field.templateOptions.required,
            options: configData.templateOptions?.options ? configData.templateOptions?.options.map(option => ({
              ...option,
              label: this._translateService.instant(option.label)
            })) : [],
          }
        };

        // Aggiungi il config e l'ordine al array temporaneo
        tempConfigs.push({ config, order: configData.order });
      } else {
        // Se il campo non è presente in userFieldsConfigData, assegna un ordine predefinito
        let config: FormlyFieldConfig = {
          key: field.key,
          type: field.type ?? 'input',
          templateOptions: {
            label: this._translateService.instant(Array.isArray(field.key) ? field.key.map(String) : String(field.key)),
            required: field.templateOptions.required,
          }
        };

        // Aggiungi il config con un ordine predefinito alla fine della lista
        tempConfigs.push({ config, order: Number.MAX_SAFE_INTEGER });
      }
    });

    // Ordina l'array temporaneo in base all'ordine
    tempConfigs.sort((a, b) => a.order - b.order);

    // Mappa l'array temporaneo per ottenere l'array finale di FormlyFieldConfig
    let orderedConfigs: FormlyFieldConfig[] = tempConfigs.map(item => item.config);

    return orderedConfigs;
  }

  updateUserGroups$(tenantId: string, userId: string, userGroupData: string[]): Observable<UserModel> {
    return this._http.post<UserModel>(`${this.baseUrl}/${tenantId}/users/${userId}/update-groups`, userGroupData);
  }
}
