import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TypeExtraField } from 'src/app/shared/models/common';
import { ExtraFieldsUtilsService } from 'src/app/shared/utilities/extra-fields-utils.servic';
import { Version } from 'src/app/shared/models/version/version';
import { CreateVersionRequest, UpdateVersionRequest } from 'src/app/shared/models/version/version-request';

@Component({
  selector: 'version-modal',
  templateUrl: './version-modal.component.html',
})
export class VersionModalComponent implements OnInit, OnDestroy {
  tenantId: string = this._tenantService.getTenantId();

  version: Version;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  dropdownsDisable = {
    make: false,
    model: false,
    fuelType: false,
    vehicleType: false,
  }

  fields: FormlyFieldConfig[];
  typeExtraFields: TypeExtraField[];

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService,
    private _communicationService: CommunicationService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _modalService: BsModalService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      vehicleTypeId: new FormControl(null, [Validators.required]),
      fuelTypeId: new FormControl(null, [Validators.required]),
      makeId: new FormControl(null, [Validators.required]),
      modelId: new FormControl(null, [Validators.required]),
      vinCode: new FormControl(null),
    });
  }

  ngOnInit() {
    this.form.get('name')?.disable();

    if (this.version?.id) {
      this.getVehicleVersion();
    }

    this.form.get('vehicleTypeId')?.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((vehicleTypeId) => {
      if (vehicleTypeId) {
        this.getExtraField();
      }
    });
  }

  getVehicleVersion() {
    this._makesService.getVersionById$(this.tenantId, this.version.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.version = response;
        this.form.patchValue({
          name: response.name,
          vinCode: response.vinCode,
        });

        this.modalStatus.isPatch = true;
        this.modalStatus.isCreate = false;

        this.form.patchValue({
          makeId: response.makeId,
          modelId: response.modelId,
          fuelTypeId: response.fuelTypeId,
          vehicleTypeId: response.vehicleTypeId,
        });

        this.dropdownsDisable.make = this.version.makeId ? true : false;
        this.dropdownsDisable.model = this.version.modelId && this.version.makeId ? true : false;
        this.dropdownsDisable.fuelType = this.version.fuelTypeId ? true : false;
        this.dropdownsDisable.vehicleType = this.version.vehicleTypeId ? true : false;
      });
  }

  getExtraField() {
    this._makesService.getVersionFields$(this.tenantId, { vehicleTypeId: this.form.value.vehicleTypeId })
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: response => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
          this.setValueFields();
        }
      });
  }

  setValueFields() {
    if (this.version) {
      this.version.extraFields = this.version.extraFields ? this.version.extraFields : {};
      this.version.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.version.extraFields, false);
      this.fields = this.typeExtraFields.map(field =>
        this._extraFieldsUtilsService.createFieldConfig(field, this.version.extraFields));
    } else {
      this.fields = this.typeExtraFields.map(field =>
        this._extraFieldsUtilsService.createFieldConfig(field, {}));
    }
  }

  onMakeSelected($event: DropdownOption) {
    this.form.patchValue({
      makeId: $event.value
    });
  }

  onModelSelected($event: DropdownOption) {
    this.form.get('name')?.enable();
    this.form.patchValue({
      modelId: $event.value
    });
  }

  onVehicleTypeSelected($event: DropdownOption) {
    this.form.patchValue({
      vehicleTypeId: $event.value
    });
  }

  onFuelTypeSelected($event: DropdownOption) {
    this.form.patchValue({
      fuelTypeId: $event.value
    });
  }

  createVehicleVersion() {
    const request = this.createRequest();
    if (request) {
      this._makesService.createVersion$(this.tenantId, request)
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        });
    }
  }

  updateVehicleVersion() {
    const request = this.createUpdateRequest();
    if (request) {
      this._makesService.updateVersion$(this.tenantId, this.version.id, request)
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        });
    }
  }

  private createRequest(): CreateVersionRequest {
    if (!this.form.value.vehicleTypeId) {
      this._toastrService.error(this._translateService.instant('VERSIONS.MESSAGES.REQUIRED_VEHICLE_TYPE'));
      return null;
    }

    if (!this.form.value.fuelTypeId) {
      this._toastrService.error(this._translateService.instant('VERSIONS.MESSAGES.REQUIRED_FUEL_TYPE'));
      return null;
    }

    if (!this.form.value.makeId) {
      this._toastrService.error(this._translateService.instant('VERSIONS.MESSAGES.REQUIRED_MAKE'));
      return null;
    }

    if (!this.form.value.modelId) {
      this._toastrService.error(this._translateService.instant('VERSIONS.MESSAGES.REQUIRED_MODEL'));
      return null;
    }

    let version: CreateVersionRequest = {
      modelId: this.form.value.modelId,
      name: this.form.value.name,
      vehicleTypeId: this.form.value.vehicleTypeId,
      fuelTypeId: this.form.value.fuelTypeId,
      vinCode: this.form.value.vinCode ?? null,
    };

    if (this.typeExtraFields.length > 0) {
      version = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.form.value, version);
      version.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, version.extraFields);
    }

    return version;
  }

  private createUpdateRequest(): UpdateVersionRequest {
    var request: UpdateVersionRequest = {
      name: this.form.value.name,
    };

    if (this.typeExtraFields.length > 0) {
      request = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.form.value, request);
      request.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, request.extraFields);
    }

    return request;
  }

  deleteVehicleVersion() {
    this.confirmModal = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'INFO_MESSAGES.CONFIRM_DELETE'
        }
      }
    });

    this.confirmModal.content.onConfirm
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this._makesService.deleteVersion$(this.tenantId, this.version.id)),
      )
      .subscribe({
        next: (response: HttpResponse<Object>) => {
          if (response.status === 204) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
