import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay, tap } from 'rxjs';
import { Me } from '../models/me/me';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { SignupFormVectore } from '../models/signup-form-vectore';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";
import { TenantService } from './tenant.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HeadquarterResponse } from '../models/headquarters';
import { MeRequest } from '../models/me/me-request';

@Injectable({
  providedIn: 'root'
})
export class MeServiceDeprecated {

  private me: Me | null = null;
  private lastFetchTime: number | null = null;
  private cacheTimeout = 300000;

  private userImageCache = new Map<string, Observable<any>>();

  private tenantId = this._tenantService.getTenantId();

  constructor(
    private _http: HttpClient,
    // private _commonService: CommonService, //can't use for circular DI issue
    private _tenantService: TenantService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _domSanitizer: DomSanitizer,
  ) { }

  registerMe$(tenant: string, signupVectoreData: SignupFormVectore) : Observable<Me> {
    return this._http.post<Me>(`${VECTORE_API_BASE_URL}/${tenant}/me`, signupVectoreData);
  }

  getMe$(tenant: string): Observable<Me> {
    // const currentTime = Date.now();
    
    // if (this.me && this.lastFetchTime && (currentTime - this.lastFetchTime < this.cacheTimeout)) {
    //   return of(this.me);
    // }
    
    return this._http.get<Me>(`${VECTORE_API_BASE_URL}/${tenant}/me`)
    .pipe(
      shareReplay(1),
      // tap((me: Me) => {
        //   this.me = me;
        //   this.lastFetchTime = currentTime; 
        // })
        );
  }
      
  patchMe$(tenant: string, patchMeRequest: MeRequest) : Observable<Me> {
    return this._http.patch<Me>(`${VECTORE_API_BASE_URL}/${tenant}/me`, patchMeRequest)
    .pipe(
      shareReplay(1),
      tap(response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('PERSONS.MESSAGES.SUCCESS_UPDATED'));          
        }
      }),
      catchError(error => {
        this._toastrService.error(error.error.detail, error.error.title);
        Sentry.withScope(scope => {
          scope.setExtra("tenantId", this.tenantId);
          Sentry.captureEvent(error);
        });
        return of(null);
      })
    // tap((updatedMe: Me) => {
    //   this.me = updatedMe;
    //   this.lastFetchTime = Date.now();
    // })
    );
  }

  listMeHeadquarters$(tenantId: string): Observable<HeadquarterResponse> {
    return this._http.get<HeadquarterResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/me/headquarters`);
  }
}
