import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonService } from './common.service';
import { Tag, TagRequest, TagResponse } from '../models/tag';
import { Observable, catchError, map, shareReplay, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private baseUrl = VECTORE_API_BASE_URL;

  constructor(
    private http: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { }

  createTag$(tenantId: string, tag: TagRequest): Observable<Tag> {
    return this.http.post<Tag>(`${this.baseUrl}/${tenantId}/tags`, tag)
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.id) {
            this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_CREATED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listTags$(tenantId: string, params: any): Observable<TagResponse> {
    return this.http.get<TagResponse>(`${this.baseUrl}/${tenantId}/tags`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getTagById$(tenantId: string, tagId: string): Observable<Tag> {
    return this.http.get<Tag>(`${this.baseUrl}/${tenantId}/tags/${tagId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateTag$(tenantId: string, tagId: string, tag: TagRequest): Observable<Tag> {
    return this.http.patch<Tag>(`${this.baseUrl}/${tenantId}/tags/${tagId}`, tag)
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.id) {
            this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_UPDATED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteTag$(tenantId: string, tagId: string): Observable<HttpResponse<Object>> {
    return this.http.delete<any>(`${this.baseUrl}/${tenantId}/tags/${tagId}`, {
      observe: 'response'
    })
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.status === 204) {
            this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toastrService.error(this._translateService.instant('TAGS.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }
}
