<div class="container-table-booking-mobile">
    <div *ngIf="bookings && bookings.length; else noData">
        <div *ngFor="let booking of bookings">
            <div class="table-row-booking" routerLink="{{routesUrl.BOOKING_DETAIL}}/{{booking.id}}">
                <div class="table-row-booking-header d-flex gap-2">
                    <div class="table-row-booking-title">{{booking.title}}</div>
                    <div [class]="booking.statusClient.cssClass">{{booking.statusClient?.text}}</div>
                </div>
                <div class="table-row-booking-content">
                    <div class="booking-type"><span>{{"TYPOLOGY" | translate}}</span> {{booking.bookingType?.name | truncate: 20}}</div>
                    <div class="booking-end-date"><span>{{"START_THE" | translate}}</span> {{booking.startDate}}</div>
                    <div class="booking-end-date"><span>{{"UNTIL_THE" | translate}}</span> {{booking.endDate}}</div>
                    <div class="booking-headerquarter"><span>{{"HEADQUARTER.NAME" | translate}}</span> {{booking.headquarter?.name | truncate: 20}}</div>
                    <div class="booking-location"><span>{{"LOCATION.NAME" | translate}}</span> {{booking.location?.name | truncate: 20}}</div>
                    <div class="booking-users">
                        <div class="driver d-flex gap-2" *ngIf="booking.user">
                            <div class="avatar-placeholder-xs person-placeholder">
                                <img *ngIf="booking.user.srcImage$ | async as imageUrl" [src]="imageUrl" alt="Image">
                            </div>
                            <div>
                                {{booking.user?.displayName}}
                            </div>
                        </div>
                        <div class="insert-by-user d-flex gap-2" *ngIf="booking.insertedByUser">
                            <div class="avatar-placeholder-xs person-placeholder">
                                <img *ngIf="booking.insertedByUser.srcImage$ | async as imageUrl" [src]="imageUrl" alt="Image">
                            </div>
                            <div>
                                {{booking.insertedByUser?.displayName}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #noData>
        <div class="d-flex flex-column gap-3 align-items-center justify-content-center text-black-50 py-2">
            <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6612_3364)">
                <rect width="64" height="40" fill="white" fill-opacity="0.01"/>
                <path d="M32 39.7031C49.6731 39.7031 64 36.5923 64 32.755C64 28.9177 49.6731 25.807 32 25.807C14.3269 25.807 0 28.9177 0 32.755C0 36.5923 14.3269 39.7031 32 39.7031Z" fill="#F5F5F5"/>
                <path d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z" stroke="#CED4DA"/>
                <path d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z" fill="#FAFAFA" stroke="#CED4DA"/>
                </g>
                <defs>
                <clipPath id="clip0_6612_3364">
                <rect width="64" height="40" fill="white"/>
                </clipPath>
                </defs>
            </svg>                    
            <span>{{ 'NO_DATA_FOUND' | translate }}</span>                    
        </div>
    </ng-template>

</div>

<div class="table-booking-footer">
    <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" [itemsPerPage]="itemsPerPage"
        (pageChanged)="pageChanged.emit($event.page)" [boundaryLinks]="true" [maxSize]="maxPagesToShowPagination"
        [rotate]="false" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
    </pagination> 
</div>