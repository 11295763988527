<div class="card-filter-task p-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="vehicleDisplayName" class="form-label">{{'VEHICLE_DISPLAY_NAME' | translate}}</label>
                <input type="text" class="form-control h-100" id="vehicleId" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="vehicleDisplayName"
                    [isAnimated]="true"
                    [typeahead]="vehicleDisplayName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectVehicleDisplayName($event)">
            </div>

            <div class="form-group">
                <label for="vehicleLicensePlate" class="form-label">{{'LICENSE_PLATE' | translate}}</label>
                <input type="text" class="form-control h-100" id="vehicleLicensePlate" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="vehicleLicensePlate"
                    [isAnimated]="true"
                    [typeahead]="vehicleLicensePlate$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectVehicleLicensePlate($event)">
            </div>

            <div class="form-group">
                <label for="userDisplayName" class="form-label">{{'USER_DISPLAY_NAME' | translate}}</label>
                <input type="text" class="form-control h-100" id="userId" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="userDisplayName"
                    [isAnimated]="true"
                    [typeahead]="userDisplayName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectUserDisplayName($event)">
            </div>

            <!-- <div class="form-group">
                <label for="headquarterName" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" id="headquarterId" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="headquarterName"
                    [isAnimated]="true"
                    [typeahead]="headquarterName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectHeadquarterName($event)">
            </div>

            <div class="form-group">
                <label for="locationName" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" id="locationId" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="locationName"
                    [isAnimated]="true"
                    [typeahead]="locationName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectLocationName($event)">
            </div> -->

            <div class="form-group d-flex gap-2">
                <input type="checkbox" class="form-check-input" id="closeOnApply" formControlName="closeOnApply">
                <label for="closeOnApply" class="form-check-label">{{'CLOSE_FILTER_ON_APPLY' | translate}}</label>
            </div>

        </form>
    </content>

    <footer class="d-flex gap-3 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="close()">{{'ACTIONS.CLOSE' | translate}}</button>

        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
