import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private tenantId: string;

  constructor(
  ) {
    this.initializeTenant();
  }

  private initializeTenant() {
    const hostname = window.location.hostname;
    if(environment.production) {
      this.tenantId = hostname.split('.')[0];
    } else {
      this.tenantId = 'global';
    }
  }

  getTenantId(): string {
    return this.tenantId;
  }
}