import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { PurchaseTypeResponse, VehicleTypesResp as VehicleTypeResponse } from '../models/vehicle';
import { CommonService } from './common.service';
import { FuelTypeResponse, Make, MakeResp, Model, ModelResp } from '../models/vehicleInfos';
import { ExtraFieldResp } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VersionResponse } from '../models/version/version-response';
import { Version } from '../models/version/version';
import { CreateVersionRequest, PatchVersionRequest } from '../models/version/version-request';
import { GetVersionFilter, ListVersionFilter } from '../models/version/version-filter';

@Injectable({
  providedIn: 'root'
})
export class MakesService {

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  listVehicleTypes$(tenantId: string, params?: any): Observable<VehicleTypeResponse> {
    return this._http.get<VehicleTypeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-types`, { params: this._commonService.buildParams(params) });
  }

  listFuelTypes$(tenantId: string, params?: any): Observable<FuelTypeResponse> {
    return this._http.get<FuelTypeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/fuel-types`, { params: this._commonService.buildParams(params) });
  }

  listPurchaseTypes$(tenantId: string, params?: any): Observable<PurchaseTypeResponse> {
    return this._http.get<PurchaseTypeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/purchase-types`, { params: this._commonService.buildParams(params) });
  }

  getVersionFields$(tenantId: string, params?: any): Observable<ExtraFieldResp> {
    return this._http.get<ExtraFieldResp>(`${VECTORE_API_BASE_URL}/${tenantId}/version-fields`, { params: this._commonService.buildParams(params) });
  }

  createMake$(tenantId: string, make: Make): Observable<Make> {
    return this._http.post<Make>(`${VECTORE_API_BASE_URL}/${tenantId}/makes`, make)
      .pipe(
        shareReplay(1),
        map(make => {
          this._toasterService.success(this._translateService.instant('MAKES.MESSAGES.SUCCESS_CREATED'));
          return make;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listMakes$(tenantId: string, params?: any): Observable<MakeResp> {
    return this._http.get<MakeResp>(`${VECTORE_API_BASE_URL}/${tenantId}/makes`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getMakeById$(tenantId: string, makeId: string, params?: any): Observable<Make> {
    return this._http.get<Make>(`${VECTORE_API_BASE_URL}/${tenantId}/makes/${makeId}`, { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateMake$(tenantId: string, makeId: string, make: Make): Observable<Make> {
    return this._http.patch<Make>(`${VECTORE_API_BASE_URL}/${tenantId}/makes/${makeId}`, make)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteMake$(tenantId: string, makeId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<Make>(`${VECTORE_API_BASE_URL}/${tenantId}/makes/${makeId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createModel$(tenantId: string, model: Model): Observable<Model> {
    return this._http.post<Model>(`${VECTORE_API_BASE_URL}/${tenantId}/models`, model)
      .pipe(
        map(model => {
          this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_CREATED'));
          return model;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listModels$(tenantId: string, params?: any): Observable<ModelResp> {
    return this._http.get<ModelResp>(`${VECTORE_API_BASE_URL}/${tenantId}/models`, { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getModelById$(tenantId: string, modelId: string, params?: any): Observable<Model> {
    return this._http.get<Model>(`${VECTORE_API_BASE_URL}/${tenantId}/models/${modelId}`, { params: this._commonService.buildParams(params) })
      .pipe(
        shareReplay(1),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateModel$(tenantId: string, modelId: string, model: Model): Observable<Model> {
    return this._http.patch<Model>(`${VECTORE_API_BASE_URL}/${tenantId}/models/${modelId}`, model)
      .pipe(
        map(response => {
          this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_UPDATED'));
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteModel$(tenantId: string, modelId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<Model>(`${VECTORE_API_BASE_URL}/${tenantId}/models/${modelId}`, { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('MODELS.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createVersion$(tenantId: string, version: CreateVersionRequest): Observable<Version> {
    return this._http.post<Version>(`${VECTORE_API_BASE_URL}/${tenantId}/versions`, version)
      .pipe(
        map(version => {
          this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_CREATED'));
          return version;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listVersions$(tenantId: string, params?: ListVersionFilter): Observable<VersionResponse> {
    return this._http.get<VersionResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/versions`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getVersionById$(tenantId: string, versionId: string, params?: GetVersionFilter): Observable<Version> {
    return this._http.get<Version>(`${VECTORE_API_BASE_URL}/${tenantId}/versions/${versionId}`, { params: this._commonService.buildParams(params) })
      .pipe(
        shareReplay(1),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateVersion$(tenantId: string, versionId: string, version: PatchVersionRequest): Observable<Version> {
    return this._http.patch<Version>(`${VECTORE_API_BASE_URL}/${tenantId}/versions/${versionId}`, version)
      .pipe(
        map(version => {
          this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_UPDATED'));
          return version;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteVersion$(tenantId: string, versionId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<any>(`${VECTORE_API_BASE_URL}/${tenantId}/versions/${versionId}`,
      { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('VERSIONS.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        }),
      );
  }

}
