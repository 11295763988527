import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { VehicleUsageFilter, VehicleUsageFilterClient } from 'src/app/shared/models/vehicle-usages/vehicle-usage-filter';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { FilterModalService, GenericFilter } from '../../../modals/filter-modal/filter-modal.service';
import { filterVehicleUsagesModal } from '../../../modals/filter-modal/filter-vehicle-usages-modal/filter-vehicle-usages-modal.component';

@Component({
  selector: 'vehicle-usages-table-filters',
  templateUrl: './vehicle-usages-table-filters.component.html',
  styleUrls: ['./vehicle-usages-table-filters.component.scss']
})
export class VehicleUsagesTableFiltersComponent implements OnInit, OnDestroy {
  @Input() titleTable: string;

  listTableFilterHeaders!: DropdownOption[];
  filterHeader: DropdownOption;

  vehicleUsageFilter: VehicleUsageFilterClient = {};
  genericFilter: GenericFilter = {};

  listMassiveActions: DropdownOption[];

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();
  @Output() onFilterModalHeaderChanged = new EventEmitter<VehicleUsageFilter>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _modalUtilsService: ModalUtilsService,
    private _filterModalService: FilterModalService
  ) { }

  ngOnInit(): void {

    this.listTableFilterHeaders = this._translateService.instant('VEHICLES.FILTERS.HEADERS');
    this.filterHeader = this.listTableFilterHeaders[0];

    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.autoComplete();
    this.getFilter();
  }

  private getFilter() {
    this._filterModalService.getFilter$(filterVehicleUsagesModal)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter: GenericFilter) => {
        this.genericFilter = filter;
        this.vehicleUsageFilter.vehicleId = filter?.vehicle?.id ?? null;
        this.vehicleUsageFilter.userId = filter?.user?.id ?? null;
        this.vehicleUsageFilter.user = filter?.user;
        this.vehicleUsageFilter.vehicle = filter?.vehicle;
        this.onFilterModalHeaderChanged.emit(this.vehicleUsageFilter);
      });
  }

  private autoComplete() {
    this.filterForm.patchValue({ headerToFilter: this.filterHeader.value });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.filterTableHeaderChanged.emit(this.filterForm);
      });
  }

  removeAllFilters() {
    this.vehicleUsageFilter = {};
    this.genericFilter = {};
    this.onFilterModalHeaderChanged.emit(this.vehicleUsageFilter);
    this._filterModalService.applyFilter(filterVehicleUsagesModal, this.genericFilter);
  }

  removeDriverFilter() {
    this.vehicleUsageFilter.user = null;
    this.vehicleUsageFilter.userId = null;
    this.genericFilter.user = null;
    this.onFilterModalHeaderChanged.emit(this.vehicleUsageFilter);
    this._filterModalService.applyFilter(filterVehicleUsagesModal, this.genericFilter);
  }

  removeVehicleFilter() {
    this.vehicleUsageFilter.vehicle = null;
    this.vehicleUsageFilter.vehicleId = null;
    this.genericFilter.vehicle = null;
    this.onFilterModalHeaderChanged.emit(this.vehicleUsageFilter);
    this._filterModalService.applyFilter(filterVehicleUsagesModal, this.genericFilter);
  }

  selectedOption($event: DropdownOption) {
    this.filterHeader = JSON.parse(JSON.stringify($event));
    this.filterForm.patchValue({ headerToFilter: $event.value });
  }

  onFilterClick() {
    this._modalUtilsService.openModalFilterVehicleUsage()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onClickExport() {
    this.exportEvent.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}