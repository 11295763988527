import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, catchError, shareReplay, tap } from 'rxjs';
import { CommonService } from './common.service';
import { MappingModel } from '../models/users';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SupplierRequest } from '../models/supplier/supplier-request';
import { Supplier } from '../models/supplier/supplier';
import { SupplierDataResponse, SupplierFieldResponse, SupplierResponse } from '../models/supplier/supplier-response';
import { SupplierFilter } from '../models/supplier/supplier-filter';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  createSupplier$(tenantId: string, supplier: SupplierRequest): Observable<Supplier> {
    return this._httpClient.post<Supplier>(`${VECTORE_API_BASE_URL}/${tenantId}/suppliers`, supplier)
      .pipe(
        tap(response => {
          if (response) {
            this._toasterService.success(this._translateService.instant("SUPPLIERS.MESSAGES.SUPPLIER_CREATED"));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listSuppliers$(tenantId: string, params?: SupplierFilter): Observable<SupplierResponse> {
    return this._httpClient.get<SupplierResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/suppliers`,
      { params: this._commonService.buildParams(params) });
  }

  getSupplier$(tenantId: string, supplierId: string, params?: SupplierFilter): Observable<Supplier> {
    return this._httpClient.get<Supplier>(`${VECTORE_API_BASE_URL}/${tenantId}/suppliers/${supplierId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        shareReplay(1),
      );
  }

  updateSupplier$(tenantId: string, supplierId: string, supplier: SupplierRequest): Observable<Supplier> {
    return this._httpClient.patch<Supplier>(`${VECTORE_API_BASE_URL}/${tenantId}/suppliers/${supplierId}`, supplier)
      .pipe(
        tap(response => {
          if (response) {
            this._toasterService.success(this._translateService.instant("SUPPLIERS.MESSAGES.SUPPLIER_UPDATED"));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteSupplier$(tenantId: string, supplierId: string): Observable<HttpResponse<Object>> {
    return this._httpClient.delete<Supplier>(`${VECTORE_API_BASE_URL}/${tenantId}/suppliers/${supplierId}`,
      { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  exportSuppliers$(tenantId: string, exportData: MappingModel, params?: SupplierFilter): Observable<HttpResponse<Blob>> {
    return this._httpClient.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/suppliers`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getSupplierData$(tenantId: string, params?: any): Observable<SupplierDataResponse> {
    return this._httpClient.get<SupplierDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/supplier-data`,
      { params: this._commonService.buildParams(params) });
  }

  listSupplierFields$(tenantId: string): Observable<SupplierFieldResponse> {
    return this._httpClient.get<SupplierFieldResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/supplier-fields`);
  }
}
